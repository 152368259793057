import React, { Component } from 'react';

import './styles.scss';

class Header extends Component {
  constructor(properties) {
    super(properties);
    this.onLogoClick = this.onLogoClick.bind(this);
    this.onLogoKeypress = this.onLogoKeypress.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onBackKeypress = this.onBackKeypress.bind(this);
  }

  onLogoClick() {
    window.ga('send', 'event', 'Links', 'Press', 'Logo');
  }

  onLogoKeypress(event) {
    if (event.key === 'Enter') {
      this.onLogoClick();
    }
  }

  onBackClick() {
    window.ga('send', 'event', 'Links', 'Press', 'Back to Adblock Fast');
  }

  onBackKeypress(event) {
    if (event.key === 'Enter') {
      this.onBackClick();
    }
  }

  

  render() {
   
    return (
      <div className='app-header'>
        <a className='logo' tabIndex='-1' href='https://adblockfast.com/' onClick={ this.onLogoClick } onKeyPress={ this.onLogoKeypress }>Adblock Fast</a>
        <a className='back' tabIndex='10' href='https://adblockfast.com/' onClick={ this.onBackClick } onKeyPress={ this.onBackKeypress }>Back to Adblock Fast</a>
      </div>
    );
  }
}

export default Header;
