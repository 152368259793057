import React, { Component } from 'react';
import Root from './containers/Root';
import {
  BrowserRouter,
  Route,
} from 'react-router-dom';

import './styles/styles.scss'

class App extends Component {

  componentDidMount () {
    window.ga('send', 'pageview', window.location.pathname + window.location.search + window.location.hash);
  }

  render () {
    return (
      <BrowserRouter>
        <Route path='/' component={Root} />
      </BrowserRouter>
    )
  }
}

export default App;
