import React from 'react';
import { Switch, Route } from 'react-router-dom';

import asyncComponent from '../../components/AsyncComponent';

const AsyncHome = asyncComponent(() => import('../Home'));
const AsyncComingSoon = asyncComponent(() => import('../ComingSoon'));

/**
 * Routes
 */

const Routes = (props) => {
  return (
    <Switch>
      <Route exact path='/' component={ AsyncHome } />
      <Route path='/403' component={ AsyncComingSoon } />
    </Switch>
  )
}

export default Routes;
