import React, { Component } from 'react';
import Routes from './routes';
import Header from '../../components/Header';

/**
 * Root component, containing routes
 */

class Root extends Component {

  render () {
    return (
      <div className='app'>
        <Header/>
        <Routes/>
      </div>
    )
  }
}

export default Root;
